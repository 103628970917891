<template>
  <div class="w-full" v-if="forceShowNotScoped">
    <not-scoped
      :scope="forceScopeName"
      :resource="contentType"
      :switch-scope-text="true"
    />
  </div>
  <div
    class="w-full"
    v-else-if="scope === 'ac' && this.selectedAccountId === 'all'"
  >
    <not-scoped scope="Account" :resource="contentType" />
  </div>
  <div
    class="w-full"
    v-else-if="scope === 'sp' && this.selectedServiceProviderId === 'all'"
  >
    <not-scoped scope="Service Provider" :resource="contentType" />
  </div>
  <main-content
    :form-action="formAction"
    :aside-text="
      __('Select :contentType to view more details', {
        contentType: contentType
      })
    "
    :editable="editable"
    v-else
  >
    <list
      slot="list"
      @select-item="handleSelect"
      @edit-item="handleSelectAndEdit"
      @view-item="handleSelectAndView"
      @open-create-modal="handleAdd"
      @open-import-modal="handleImport"
      @handleCancel="handleCancel"
      @handleSuccess="replaceContent"
      @replaceContent="replaceContent"
      @clear-selection="clickAwayFromCard"
      @clear-aside="clearAsideElement"
      @changeContent="handleChangeContent"
      @update-multi-selected="handleUpdateMultiSelected"
      :can-multi-select="canMultiSelect"
      :id="selectedIndex"
      :content-type="contentType"
      :primary-key="primaryKey"
      :form-action="formAction"
      :show-only="showOnly"
    ></list>
    <create-or-edit
      v-if="editable"
      slot="form"
      :can-attach-as-file="canAttachAsFile"
      :id="selectedIndex"
      :content="selectedContent"
      :form-action="formAction"
      :checked-contents="selectedItems"
      @edit-item="handleEdit"
      @extend-item="handleExtend"
      @handleCancel="handleCancel"
      @clear-selection="clickAwayFromCard"
      @add-attachment="handleAddAttachment"
      @update-multi-selected="handleUpdateMultiSelected"
    ></create-or-edit>
  </main-content>
</template>

<script>
import MainContent from "@/layout/components/mainContentNew";
import _ from "lodash";
import NotScoped from "@/components/NotScoped";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    MainContent,
    NotScoped
  },

  props: {
    canAttachAsFile: {
      required: false,
      type: Boolean,
      default: false
    },
    canMultiSelect: {
      required: false,
      type: Boolean,
      default: true
    },
    showOnly: {
      required: false,
      type: String,
      default: () => "all"
    }
  },

  data() {
    return {
      contentType: "",
      primaryKey: "",
      formAction: "",
      selectedIndex: null,
      selectedContent: null,
      listClickStatus: false,
      scope: "sp",
      editable: true,
      forceShowNotScoped: false,
      forceScopeName: "Account",
      selectedItems: [],
      items: []
    };
  },
  computed: {
    contents: {
      get() {
        return this.items;
      },
      set(values) {
        this.items = values;
      }
    },
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      enabledFeatures: state => state.enabled_features
    }),

    ...mapGetters("app", {
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination"
    })
  },

  methods: {
    setSelectedObject(index, content) {
      this.selectedContent = content;
      this.selectedIndex = index;
    },

    handleAdd(params) {
      this.formAction = "add";
      this.setSelectedObject(-1, this.initContent(params));
    },

    handleImport(params) {
      this.formAction = "import";
      this.setSelectedObject(-1, this.initContent(params));
    },

    handleSelect(id, content) {
      this.formAction = "select";
      this.setSelectedObject(id, content);
    },

    handleChangeContent({ contentType, primaryKey, contents }) {
      // if you want to invoke this method,
      // then you should update the contents prop to a get() set() combination
      // or remove teh contents initialization from index.vue for that view and
      // emit changeContent event from list.vue for that item.
      // refer view/analyse/reports
      this.contentType = contentType;
      this.primaryKey = primaryKey;
      this.contents = contents;
    },

    handleSelectAndEdit(id, content) {
      this.handleEdit();
      this.setSelectedObject(id, content);
    },

    handleEdit() {
      this.formAction = "edit";
    },

    handleSelectAndView(id, content) {
      this.handleView();
      this.setSelectedObject(id, content);
    },

    handleView() {
      this.formAction = "view";
    },

    handleExtend() {
      this.formAction = "extend";
    },

    handleAddAttachment(file_url, file_id, file_name) {
      this.$emit("add-attachment", file_url, file_id, file_name);
    },

    handleCancel() {
      if (["add", "select"].includes(this.formAction)) {
        this.clickAwayFromCard();
      } else {
        if (["edit", "extend"].includes(this.formAction)) {
          this.formAction = "select";
        }
      }
    },

    clickAwayFromCard() {
      this.formAction = "";
      this.setSelectedObject(-1, this.initContent());
    },

    clearAsideElement() {
      this.formAction = "blank";
    },

    replaceContent(content) {
      if (content) {
        const index = _.findIndex(this.contents, {
          [this.primaryKey]: content[this.primaryKey]
        });

        const contentUpdated = !_.isEqual(content, this.contents[index]);
        const contentObj =
          index === -1
            ? this.initContent()
            : contentUpdated
            ? this.contents[index]
            : content;

        this.setSelectedObject(index, contentObj);
      }
    },

    handleUpdateMultiSelected(multiSelected) {
      this.selectedItems = [...multiSelected];
      if (_.isEmpty(this.selectedItems)) {
        this.formAction = "";
      } else {
        this.formAction = "select";
      }
      this.setSelectedObject(-1, this.initContent());
    },
    initContent() {
      return {};
    }
  },
  watch: {
    contents: {
      deep: true,
      handler: function() {
        this.replaceContent(this.selectedContent);
      }
    },
    selectedServiceProviderAccountCombination: {
      immediate: true,
      handler() {
        this.clickAwayFromCard();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}
</style>
