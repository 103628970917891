<template>
  <div class="audio-bar" :class="{ disabled }">
    <div class="audio-bar-contents">
      <div :class="`control-group ${simplePlayer ? 'simplePlayer' : ''}`">
        <div class="audio-bar-content">
          <div
            :class="{ disabled: controlDisabled }"
            class="audio-control"
            v-if="file && showReload && !generatingAudio && !simplePlayer"
            @click="!controlDisabled && $emit('get-preview')"
          >
            <img src="/icons/reload.svg" />
          </div>
          <div
            :class="{ disabled }"
            v-else-if="(generatingAudio || (file && !loaded)) && !simplePlayer"
          >
            <i class="el-icon-loading"></i>
          </div>
          <div
            :class="{ disabled }"
            class="audio-control"
            v-else-if="!file && !loaded && !simplePlayer"
            @click="$emit('get-preview')"
          >
            {{ __("preview") }}
          </div>
          <div
            :class="{ disabled: controlDisabled }"
            class="audio-control"
            :style="{ display: moveDownloadBtn ? 'block' : 'flex' }"
            v-else
          >
            <div class="audio-control-item">
              <img
                class="audio-control-item-icon"
                v-if="!playing || paused"
                :src="iconPlay"
                @click="playing ? pause() : play()"
                alt="Play Icon"
              />
              <img
                class="audio-control-item-icon"
                v-else
                :src="iconPause"
                @click="playing ? pause() : play()"
                alt="Pause Icon"
              />
            </div>
            <div
              :class="
                `audio-control-item ${moveDownloadBtn ? 'top' : 'centre'}`
              "
              @click="downloadAudioFile(file)"
            >
              <img
                class="audio-control-item-icon"
                v-if="file"
                :src="iconDownload"
                title="Download Audio File"
                alt="Download Icon"
              />
            </div>
            <div class="audio-control-item">
              <img
                class="audio-control-item-icon"
                v-if="showDelete"
                :src="iconDelete"
                title="Delete Audio File"
                alt="Delete Icon"
                @click="deleteAudioFile()"
                id="showDeleteRecording"
              />
            </div>
          </div>
        </div>

        <div v-if="loaded && !simplePlayer" class="audio-bar-content">
          {{ currentTime }}
        </div>
      </div>
      <div
        class="audio-bar-content audio-progress"
        :class="{ disabled: controlDisabled }"
        v-if="!simplePlayer || showSeekBar"
      >
        <el-slider
          :disabled="controlDisabled"
          v-model="percentage"
          @change="setPosition"
          :show-tooltip="false"
        />
      </div>
      <div
        class="audio-bar-content"
        :class="{ disabled: controlDisabled }"
        v-if="!simplePlayer && showDuration"
      >
        {{ duration }}
      </div>
      <div
        class="audio-bar-content"
        :class="{ disabled: controlDisabled }"
        v-if="simplePlayer && showDuration"
      >
        {{ currentTime }} / {{ duration }}
      </div>
      <div
        :class="{ disabled: controlDisabled }"
        class="audio-control"
        v-if="file && showDownload && !generatingAudio"
        @click="!controlDisabled && download()"
      >
        <img src="/icons/download.svg" />
      </div>
    </div>
    <audio
      id="player"
      ref="player"
      v-on:ended="ended"
      v-on:canplay="canPlay"
      :src="file"
    >
      {{ __("Your browser does not support the audio element.") }}
    </audio>
  </div>
</template>
<script>
import FileDownloader from "@/mixins/FileDownloader";

const formatTime = second =>
  new Date(second * 1000).toISOString().substr(15, 4);
export default {
  name: "AudioPlayer",
  props: {
    file: {
      type: String,
      default: ""
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showReload: {
      type: Boolean,
      default: false
    },
    generatingAudio: {
      type: Boolean,
      default: false
    },
    ended: {
      type: Function,
      default: () => {}
    },
    canPlay: {
      type: Function,
      default: () => {}
    },
    simplePlayer: {
      type: Boolean,
      default: false
    },
    showSeekBar: {
      type: Boolean,
      default: false
    },
    showDuration: {
      type: Boolean,
      default: false
    },
    iconPlay: {
      type: String,
      default: "/icons/play.svg"
    },
    iconDownload: {
      type: String,
      default: "/icons/download_white.svg"
    },
    iconPause: {
      type: String,
      default: "/icons/pause_white.svg"
    },
    iconDelete: {
      type: String,
      default: "/icons/delete.svg"
    },
    showDownload: {
      type: Boolean,
      default: false
    },
    moveDownloadBtn: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [FileDownloader],
  computed: {
    duration: function() {
      return this.audio ? formatTime(this.totalDuration) : "0:00";
    },

    controlDisabled() {
      return this.disabled || !this.loaded;
    }
  },
  data() {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "0:00",
      audio: undefined,
      totalDuration: 0
    };
  },
  methods: {
    setPosition() {
      this.audio.currentTime = parseInt(
        String((this.audio.duration / 100) * this.percentage)
      );
    },
    stop() {
      this.paused = this.playing = false;
      this.audio.pause();
      this.audio.currentTime = 0;
    },
    play() {
      if (this.playing) return;
      this.paused = false;
      this.audio.play().then(() => (this.playing = true));
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    download() {
      this.audio.pause();
      window.open(this.file, "download");
    },
    downloadAudioFile(url) {
      this.audio.pause();
      this.downloadFileFromURL(url);
    },
    mute() {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
      this.volumeValue = this.isMuted ? 0 : 75;
    },
    reload() {
      this.audio.load();
    },
    deleteAudioFile() {
      this.$emit("deleteRecord", this.record);
    },
    _handleLoaded: function() {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
          this.paused = this.playing = false;
          this.audio.currentTime = 0;
        }
        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error(__("Failed to load sound file"));
      }
    },
    _handlePlayingUI: function() {
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
    },
    _handlePlayPause: function(e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "0:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function() {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    }
  },
  mounted() {
    this.audio = this.$refs.player;
    this.init();
  },

  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },

  watch: {
    file: "reload"
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  color: #a0a8b5 !important;
  cursor: not-allowed !important;
}

.audio-bar {
  .audio-bar-contents {
    .control-group.simplePlayer {
      min-width: unset;
    }
  }
}
.audio-bar {
  display: flex;
  flex-direction: column;
  background-color: #222933;
  color: white;

  .audio-bar-contents {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    padding-left: 5px;
    padding-right: 5px;

    .control-group {
      display: flex;
      flex-direction: row;
      min-width: 50px;
      align-items: center;
    }

    .audio-bar-content {
      padding-left: 3px;
      padding-right: 3px;
    }

    .audio-control {
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .audio-control-item {
      width: 24px;
      text-align: center;
    }

    .audio-control-item.top {
      position: absolute;
      top: 5px;
      right: 5px;
      ::v-deep img {
        filter: brightness(0) saturate(100%) invert(100%);
      }
    }
    .audio-control-item-icon {
      vertical-align: middle;
    }

    .audio-progress {
      padding-left: 3px;
      padding-right: 3px;
      flex: 1;
      z-index: 0;

      &.disabled {
        ::v-deep .el-slider__button {
          background-color: #a0a8b5 !important;
          border-color: #a0a8b5 !important;
        }
      }

      ::v-deep .el-slider__runway {
        height: 2px;
        background-color: #a0a8b5;
        ::v-deep .el-slider__bar {
          height: 2px;
          background-color: white;
        }

        ::v-deep.el-slider__button-wrapper {
          height: 34px;
          width: 12px;

          ::v-deep .el-tooltip {
            vertical-align: middle;
          }
        }
        ::v-deep .el-slider__button {
          width: 0;
          height: 5px;
          border-radius: 2px;
          border-color: white;
        }
      }
    }
  }
}

.audio-bar {
  &.audio-bar.simple-player {
    border-radius: 500px;
    .audio-bar-contents {
      justify-content: center;
      .audio-bar-content {
        padding: 0;
      }
    }
  }
}
</style>
