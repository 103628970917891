<template>
  <div>
    <el-upload
      v-if="!fileUrl"
      class="file-uploader"
      :headers="AcHeaders"
      :action="getUploadUrl"
      :data="formData"
      :show-file-list="true"
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-upload="beforeFileUpload"
      :on-change="handleChange"
      :limit="1"
      :multiple="false"
      drag
    >
      <UploaderInfoIcon />
      <div class="el-upload__tip responsive-tip-text" slot="tip">
        {{ this.tipTextFinal }}
      </div>
    </el-upload>

    <div
      v-if="fileUrl"
      style="display: flex;align-items: center;justify-content: flex-start"
    >
      <div
        style="height: 120px; width: 140px; display: flex;align-items: center;justify-content: center;border-radius: 6px; border: var(--theme-color) 1px dashed"
        v-if="fileUrl"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center; justify-content: center"
        >
          <el-popover placement="top-start" trigger="hover">
            {{ fileName$ }}
            <div
              slot="reference"
              style="max-width: 100px; text-overflow: ellipsis ;white-space: nowrap;
              overflow: hidden;font-size: 0.8em; margin-top: 5px"
            >
              <span class="uploaded-file-name">{{ fileName$ }}</span>
            </div>
          </el-popover>
          <div style="padding-top: 5px;">
            <el-button
              @click="handleDelete"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUploader from "./../BaseUploader";
import UploaderInfoIcon from "../UploaderInfoIcon.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [BaseUploader],
  components: {
    UploaderInfoIcon
  },
  props: {
    fileName: {
      required: false,
      type: String
    },
    tipText: {
      required: false,
      type: String,
      default() {
        return "";
      }
    }
  },

  computed: {
    ...mapGetters("app", {
      fileExtensionWhitelistMessage: "fileExtensionWhitelistMessage"
    }),

    tipTextFinal() {
      return this.tipText || this.fileExtensionWhitelistMessage;
    }
  },

  methods: {
    /**
     * Check if the file size is within limit
     * @param { File } file
     * @param { number } limitSize In Mb
     * @returns {boolean}
     */
    fileSizeLtInMB(file, limitSize) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize < limitSize) {
        this.$emit("file-info", file);
        return true;
      }
      this.$message({
        message: __("file size exceeded :max :unit", {
          max: limitSize,
          unit: "MB"
        }),
        type: "error"
      });
      return false;
    },

    beforeFileUpload(file) {
      if (!this.isFileAllowed(file)) {
        this.$message({
          message: __("Only these files are allowed: :allowed", {
            allowed: this.allowedTypesReadable
          }),
          type: "error"
        });
        return false;
      }

      let sizeLimit = 2;
      if (file.type === "video/mp4") {
        sizeLimit = 20;
      }
      return this.fileSizeLtInMB(file, sizeLimit);
    },

    handleChange(file, fileList) {
      this.$emit("on-change", file, fileList);
    },
    handleDelete() {
      this.$emit("on-delete");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
::v-deep .el-image__inner {
  vertical-align: center !important;
}
.file-uploader ::v-deep .el-upload {
  border: 1px dashed $--border-color-light;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .el-upload-dragger {
    border-color: var(--theme-color) !important;
    width: 140px;
    height: 140px;
    padding: 5px;
  }
}
.file-uploader ::v-deep .el-upload:hover {
  border-color: var(--theme-color);
}
.file-uploader-icon {
  font-size: 28px;
  color: $--color-text-secondary;
  min-width: 125px;
  min-height: 125px;
  max-width: 125px;
  max-height: 125px;
  line-height: 125px;
  text-align: center;
}
.file {
  min-width: 125px;
  min-height: 125px;
  max-width: 150px;
  max-height: 150px;
  display: block;
}

.responsive-tip-text {
  white-space: normal;
  overflow-wrap: break-word;
  word-break: normal;
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
}
</style>
